import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carouselBlocks = document.querySelectorAll('.testimonials-carousel');

  if (0 < carouselBlocks.length) {
    carouselBlocks.forEach((carouselBlock) => {
      const slides = carouselBlock.querySelectorAll('.swiper-slide');
      const args = {
        slidesPerView: 1,
        spaceBetween: 20,
        centerInsufficientSlides: true,

        navigation: {
          prevEl: carouselBlock.querySelector('.swiper-button-prev'),
          nextEl: carouselBlock.querySelector('.swiper-button-next'),
        },

        breakpoints: {
          680: {
            slidesPerView: 2,
          },
          1060: {
            slidesPerView: 3,
          },
        },

        on: {
          lock: () => {
            carouselBlock
              .querySelector('.testimonials-carousel__carousel-navigation')
              .classList.remove('visible');
          },
          unlock: () => {
            carouselBlock
              .querySelector('.testimonials-carousel__carousel-navigation')
              .classList.add('visible');
          },
        },
      };

      if (3 < slides.length) {
        args.loop = true;
      } else {
        args.watchOverflow = true;
      }
      new Swiper(
        carouselBlock.querySelector('.testimonials-carousel__carousel'),
        args
      );
    });
  }
});
